import React from "react";
import { HomeView } from "./subPage/homeView";
import { Header } from "./component/header";
import { getAlertByID } from "../api/fakeData";
import { navigateToUrl } from "single-spa";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: "Home View",
            alert: {},
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        return (
            <div>
                <Header page={this.state.currentPage} />
                <HomeView
                    alertList={this.props.alertList}
                    OnPageChange={this.OnPageChange}
                    getAlert={this.getAlert}
                />
            </div>
        );
    }
    OnPageChange = (page) => {
        navigateToUrl("/alert-management");
    };
    getAlert = (id) => {
        let alert = getAlertByID(id);
        this.setState({ alert: alert });
    };
}

export { Dashboard };
