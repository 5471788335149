import React, { Component } from "react";
import { Line } from "react-chartjs-2";

class ModelLineChartItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
        };
    }

    options = (min, max, stepSize, labelFontSize) => {
        return {
            maintainAspectRatio: false,
            legend: {
                labels: {
                    fontColor: "white",
                },
            },
            responsive: true,
            tooltips: {
                mode: "label",
            },
            plugins: {
                dataLabels: {
                    display: false,
                },
            },
            elements: {
                line: {
                    tension: 0,
                    fill: false,
                },
                point: {
                    radius: 0,
                    hitRadius: 5,
                    hoverRadius: 5,
                },
            },
            scales: {
                ticks: {
                    fontColor: "#FFF",
                },
                xAxes: [
                    {
                        display: true,
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            fontColor: "#FFF",
                            fontSize: labelFontSize,
                            autoSkip: true,
                            maxTicksLimit: 12,
                            maxRotation: 90,
                            minRotation: 90,
                        },
                        //labels: {
                        //    show: true
                        //}
                    },
                ],
                yAxes: [
                    {
                        type: "linear",
                        display: true,
                        gridLines: {
                            display: true,
                            color: "#FFF",
                            zeroLineColor: "#FFF",
                            drawBorder: false,
                        },
                        position: "left",
                        id: "y-axis-1",
                        ticks: {
                            fontColor: "#FFF",
                            fontSize: labelFontSize,
                            autoSkip: true,
                            stepSize: stepSize,
                            min: min,
                            max: max,
                        },
                        //labels: {
                        //    show: true
                        //}
                    },
                    {
                        type: "linear",
                        display: false,
                        gridLines: {
                            display: true,
                            color: "#FFF",
                            zeroLineColor: "#FFF",
                            drawBorder: false,
                        },
                        position: "left",
                        id: "y-axis-2",
                        ticks: {
                            fontColor: "#FFF",
                            autoSkip: true,
                            stepSize: stepSize,
                            min: min,
                            max: max,
                        },
                    },
                ],
            },
        };
    };
    render() {
        let rem = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );
        let options = this.options(
            this.props.min,
            this.props.max,
            this.props.stepSize,
            0.7 * rem
        );
        return (
            <div
                style={{
                    padding: "1rem",
                    paddingBottom: "0",
                    width: "100%",
                    height: "14rem",
                }}
            >
                <Line data={this.props.data} options={options} />
            </div>
        );
    }
}

export { ModelLineChartItem };
