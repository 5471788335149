import React from "react";
import { navbarStyle } from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        return (
            <div style={navbarStyle.backgroundStyle}>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <span
                    style={navbarStyle.headerFont}
                    onClick={() => {
                        navigateToUrl("/dashboard");
                    }}
                >
                    KPMG Transaction Monitoring - {this.props.page}
                </span>
            </div>
        );
    }
}

export { Header };
