import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { mapping } from "@aim-mf/styleguide";
import { headerMapping } from "../../api/fakeData";

const HeaderRender = (props) => {
    console.log(props);
    return <span style={mapping["headerCellStyle"]}>{props.title}</span>;
};

class AlertTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        let rem = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );
        return (
            <div>
                <Grid
                    style={{
                        maxHeight: "20rem",
                        height: "fit-content",
                        fontSize: "0.875rem",
                        margin: "1.25rem",
                        backgroundColor: "rgba(0,0,0,0.4)",
                    }}
                    data={this.props.alertList}
                    onRowClick={this.onRowClick}
                    headerCellRender={HeaderRender.render}
                >
                    <Column
                        field="id"
                        title="ALERT ID"
                        width={(300 / 16) * rem + "px"}
                    />
                    <Column
                        field="customer_id"
                        title="CUSTOMER ID"
                        // width={(300 / 16) * rem + "px"}
                    />

                    <Column
                        field="priority"
                        title="PRIORITY"
                        width={(300 / 16) * rem + "px"}
                    />

                    <Column
                        field="alert_date"
                        title="ALERT DATE"
                        width={(300 / 16) * rem + "px"}
                    />

                    <Column
                        field="last_modified_date"
                        title="LAST MODIFIED DATE"
                        width={(300 / 16) * rem + "px"}
                    />

                    <Column
                        field="status"
                        title="STATUS"
                        width={(300 / 16) * rem + "px"}
                    />
                </Grid>
            </div>
        );
    }
    onRowClick = (row) => {
        if (row.dataItem.id === "28") {
            this.props.getAlert(row.dataItem.id);
            this.props.OnPageChange(headerMapping.overview);
        }
    };
}

class PastTransactionDetailsTables extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        let rem = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );
        return (
            <div>
                <Grid
                    style={{
                        maxHeight: "20rem",
                        height: "fit-content",
                        fontSize: "0.875rem",
                        marginTop: "1.25rem",
                        marginBottom: "1.25rem",
                        backgroundColor: "rgba(0,0,0,0.4)",
                    }}
                    data={this.props.data}
                    onRowClick={this.onRowClick}
                    headerCellRender={HeaderRender.render}
                >
                    <Column
                        field="date"
                        title="DATE"
                        width={(140 / 18) * rem + "px"}
                    />
                    <Column
                        field="id"
                        title="TRANSACTION ID"
                        width={(200 / 18) * rem + "px"}
                    />
                    <Column
                        field="customer_id"
                        title="CUSTOMER ID"
                        width={(150 / 18) * rem + "px"}
                    />
                    <Column
                        field="account_id"
                        title="ACCOUNT ID"
                        width={(150 / 18) * rem + "px"}
                    />
                    <Column
                        field="currency"
                        title="CURRENCY CODE"
                        width={(170 / 18) * rem + "px"}
                    />
                    <Column
                        field="amount"
                        title="AMOUNT"
                        width={(100 / 16) * rem + "px"}
                    />
                    <Column
                        field="type"
                        title="DR/CR"
                        width={(100 / 16) * rem + "px"}
                    />
                    <Column field="narration" title="NARRATION" />
                    <Column field="counterparty" title="COUNTERPARTY NAME" />
                    <Column
                        field="transubtype"
                        title="TRANSUBTYPE"
                        width={(180 / 18) * rem + "px"}
                    />
                    <Column
                        field="jurisdiction"
                        title="HIGH RISK JURISDICTION"
                        width={(240 / 18) * rem + "px"}
                    />
                </Grid>
            </div>
        );
    }
}

export { AlertTable, PastTransactionDetailsTables };
