import React from "react";
import {
    DarkBackgroundColor,
    homeDarkBackgroundColor,
    midDarkBackgroundColor,
    modelEvalFail,
    modelEvalPass,
} from "../../colorSchema";
import { ModelLineChart } from "./chart";
import { IconSVG } from "@aim-mf/styleguide";

class ModelEval extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div
                style={{
                    display: "inline-flex",
                    width: "100%",
                    justifyContent: "space-between",
                }}
            >
                <div style={{ width: "35rem" }}>
                    {this.props.left_title.split("\n").map((item) => {
                        return <div style={titleStyle}>{item}</div>;
                    })}
                    <div
                        style={{
                            backgroundColor: homeDarkBackgroundColor,
                            height: "22rem",
                        }}
                    >
                        <div style={{ height: "6.5rem" }}>
                            <div
                                style={{
                                    display: "inline-flex",
                                    justifyContent: "space-around",
                                    width: "100%",
                                    marginTop: "1rem",
                                }}
                            >
                                <LeftBlock
                                    title={"Precision"}
                                    data={this.props.precision}
                                />
                                <LeftBlock
                                    title={"Recall"}
                                    data={this.props.recall}
                                />
                                <LeftBlock
                                    title={"Accuracy"}
                                    data={this.props.accuracy}
                                />
                            </div>
                            <div
                                style={{ color: "white", textAlign: "center" }}
                            >
                                Model Performance
                            </div>
                        </div>
                        <ModelLineChart
                            data={this.props.leftData}
                            stepSize={5}
                            min={0}
                            max={15}
                        />
                    </div>
                </div>
                <div style={{ width: "78.75rem" }}>
                    {this.props.right_title.split("\n").map((item) => {
                        return <div style={titleStyle}>{item}</div>;
                    })}
                    <div
                        style={{
                            backgroundColor: homeDarkBackgroundColor,
                            height: "22rem",
                            display: "inline-flex",
                        }}
                    >
                        {this.props.rightData.map((rightDataItem) => {
                            return (
                                <div style={{ width: "39.375rem" }}>
                                    <div
                                        style={{
                                            height: "6.5rem",
                                            color: "white",
                                            padding: "1rem",
                                        }}
                                    >
                                        <div>{rightDataItem.title}</div>
                                        <div>
                                            Goodness of fit test:{" "}
                                            <span
                                                style={{
                                                    color:
                                                        rightDataItem.fit_test ===
                                                        "Failed"
                                                            ? modelEvalFail
                                                            : modelEvalPass,
                                                }}
                                            >
                                                {rightDataItem.fit_test}
                                            </span>
                                        </div>
                                        <div>
                                            Chi-squared test P Value:{" "}
                                            <span
                                                style={{
                                                    color:
                                                        rightDataItem.p_value <
                                                        0.01
                                                            ? modelEvalFail
                                                            : modelEvalPass,
                                                }}
                                            >
                                                {rightDataItem.p_value}
                                            </span>
                                        </div>
                                    </div>
                                    <ModelLineChart
                                        data={rightDataItem.data}
                                        stepSize={20}
                                        min={0}
                                        max={100}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

function LeftBlock(props) {
    const colorMapping = {
        Precision: { left: "rgb(51,125,230)", right: "rgb(1,145,210)" },
        Recall: { left: "rgb(25,149,194)", right: "rgb(35,183,229)" },
        Accuracy: { left: "rgb(0,144,210)", right: "rgb(139,184,241)" },
    };
    const iconNameMapping = {
        Precision: "check",
        Recall: "recall",
        Accuracy: "star",
    };
    return (
        <div style={leftBlockStyle}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                    width: "40%",
                    backgroundColor: colorMapping[props.title].left,
                }}
            >
                <IconSVG
                    name={iconNameMapping[props.title]}
                    color={"white"}
                    size={1.5}
                />
            </div>
            <div
                style={{
                    height: "100%",
                    width: "60%",
                    backgroundColor: colorMapping[props.title].right,
                    padding: "0.3rem",
                    paddingLeft: "0.7rem",
                    color: "white",
                    lineHeight: "1.4rem",
                }}
            >
                <div>{props.title}</div>
                <div>{props.data}</div>
            </div>
        </div>
    );
}

const titleStyle = {
    backgroundColor: DarkBackgroundColor,
    padding: "0.2rem",
    paddingLeft: "1rem",
    color: "white",
    height: "2rem",
};

const leftBlockStyle = {
    display: "inline-flex",
    width: "9rem",
    height: "3.5rem",
};

export { ModelEval };
