import React from "react";
import { AlertTable, PastTransactionDetailsTables } from "../component/table";
import { DonutChart, AreaChart } from "../component/chart";
import { dashboardStyle } from "@aim-mf/styleguide";
import { ModelEval } from "../component/modelEval";
import {
    overallModelIntegrityData,
    overallModelIntegrityGoodnessData,
    supervisedIntegrityData,
    supervisedIntegrityGoodnessData,
    unsupervisedIntegrityData,
    unsupervisedIntegrityGoodnessData,
} from "../../api/fakeData";
class HomeView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        return (
            <div>
                <div style={{ display: "inline-flex" }}>
                    <div
                        style={{
                            backgroundColor: "rgba(0,0,0,0.4)",
                            height: "30rem",
                            width: "35rem",
                            margin: "1.25rem",
                        }}
                    >
                        <DonutChart data={this.props.alertList} />
                    </div>
                    <div
                        style={{
                            backgroundColor: "rgba(0,0,0,0.4)",
                            height: "30rem",
                            width: "78.75rem",
                            margin: "1.25rem",
                        }}
                    >
                        <AreaChart />
                    </div>
                </div>
                <div style={{ margin: "1.25rem" }}>
                    <ModelEval
                        left_title={"Overall Model Integrity"}
                        right_title={
                            "Training data vs Live data Goodness of fit"
                        }
                        precision={"86%"}
                        recall={"90%"}
                        accuracy={"89%"}
                        rightData={overallModelIntegrityGoodnessData}
                        leftData={overallModelIntegrityData}
                    />
                </div>
                <div style={{ margin: "1.25rem" }}>
                    <ModelEval
                        left_title={
                            "Supervised Model Integrity\n Last retrained: 01/18/2021"
                        }
                        right_title={
                            "Training data vs Live data Goodness of fit\n   "
                        }
                        precision={"79%"}
                        recall={"94%"}
                        accuracy={"95%"}
                        rightData={supervisedIntegrityGoodnessData}
                        leftData={supervisedIntegrityData}
                    />
                </div>
                <div style={{ margin: "1.25rem" }}>
                    <ModelEval
                        left_title={"Unsupervised Model Integrity"}
                        right_title={
                            "Training data vs Live data Goodness of fit"
                        }
                        precision={"86%"}
                        recall={"90%"}
                        accuracy={"89%"}
                        rightData={unsupervisedIntegrityGoodnessData}
                        leftData={unsupervisedIntegrityData}
                    />
                </div>
                <div
                    style={{
                        ...dashboardStyle.sectionTitleStyle,
                        paddingLeft: "2rem",
                        width: "fit-content",
                    }}
                >
                    Alerts
                </div>
                <AlertTable
                    alertList={this.props.alertList}
                    OnPageChange={this.props.OnPageChange}
                    getAlert={this.props.getAlert}
                />
            </div>
        );
    }
}

export { HomeView };
