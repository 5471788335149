import { createStore } from "redux";
import rootReducers from "../reducer/reducers";

const store = createStore(
    rootReducers,
    undefined,
    window.__REDUX_DEVTOOLS_ENTENSION__ && window.__REDUX_DEVTOOLS_ENTENSION__()
);

export { store };
