import { LOAD_RISK, LOADING_RISK } from "../constant/actionType";

const initialState = {
    loadingRisk: true,
    riskList: [],
};

const userReducer = (state = initialState, action) => {
    if (action.type === LOADING_RISK) {
        return { ...state, loadingRisk: true };
    }
    if (action.type === LOAD_RISK) {
        return {
            ...state,
            riskList: action.payload.results.data,
            loadingRisk: false,
        };
    }
    return state;
};

export default userReducer;
