import React from "react";
import { Dashboard } from "./page/dashboard";
import { loadAlertApiCall, loadVendorApiCall } from "./api/alertApi";
import {
    loadAlert,
    loadingAlert,
    loadingVendor,
    loadVendor,
} from "../redux/action/actions";
import { connect } from "react-redux";
import "./app.scss";
import "bootstrap/dist/css/bootstrap.css";
import { ScreenClassProvider } from "react-grid-system";
import axios from "axios";
import { getAlertList, fakeVendorAnalysisData } from "./api/fakeData";

class App extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        axios.defaults.headers.common["Authorization"] =
            "token " + sessionStorage.getItem("token");
        // loadAlertApiCall(
        //     this.props.loadingAlertDispatch,
        //     this.props.loadAlertDispatch
        // );
        // loadVendorApiCall(
        //     this.props.loadingVendorDispatch,
        //     this.props.loadVendorDispatch
        // )
    }

    render() {
        let fakeAlertList = getAlertList();
        return (
            <ScreenClassProvider>
                <Dashboard
                    alertList={fakeAlertList}
                    vendorList={fakeVendorAnalysisData}
                    // loading={
                    //     this.props.loadingAlert ||
                    //     this.props.loadingRisk ||
                    //     this.props.loadingUser
                    // }
                    // userList={this.props.userList}
                    // alertList={this.props.alertList}
                />
            </ScreenClassProvider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        alertList: state.alert.alertList,
        loadingAlert: state.alert.loadingAlert,
        vendorList: state.alert.vendorList,
        loadingVendor: state.alert.loadingVendor,
    };
};

const mapDispatchToProps = {
    loadAlertDispatch: loadAlert,
    loadingAlertDispatch: loadingAlert,
    loadVendorDispatch: loadVendor,
    loadingVendorDispatch: loadingVendor,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
// export { reloadRisk };
