import React from "react";
import {
    Chart,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesLabels,
    ChartTooltip,
    ChartArea,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartCategoryAxisLabels,
    ChartTitle,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartValueAxisLabels,
} from "@progress/kendo-react-charts";
import { ModelLineChartItem } from "./modelLineChartItem";

const orangeColor = "rgba(255,111,27,0.9)";
const yellowColor = "rgba(255,192,0,0.9)";
const greenColor = "rgba(112,173,71,0.9)";
const blueColor = "rgba(68,114,196,0.9)";

class DonutChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fakeData: [
                { status: "Open", alerts: 263, color: orangeColor },
                { status: "In Progress", alerts: 79, color: yellowColor },
                { status: "Escalated", alerts: 22, color: greenColor },
            ],
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {}
    render() {
        let rem = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );
        return (
            <div>
                <Chart donutCenterRender={this.donutCenterRenderer}>
                    <ChartArea
                        height={(480 / 16) * rem}
                        width={(560 / 16) * rem}
                        background={"rgba(0,0,0,0)"}
                    />
                    <ChartTooltip render={this.renderTooltip} />
                    <ChartSeries>
                        <ChartSeriesItem
                            type="donut"
                            data={this.state.fakeData}
                            holeSize={(110 / 16) * rem}
                            categoryField="status"
                            field="alerts"
                            colorField="color"
                        >
                            <ChartSeriesLabels
                                color="#fff"
                                background="none"
                                content={(e) => {
                                    e.status;
                                }}
                            />
                        </ChartSeriesItem>
                    </ChartSeries>
                    <ChartLegend
                        margin={(30 / 16) * rem}
                        spacing={(40 / 16) * rem}
                        visible={true}
                        position={"bottom"}
                        labels={{ color: "white" }}
                    />
                </Chart>
            </div>
        );
    }

    getTotalAlert = () => {
        return this.state.fakeData.reduce((a, b) => a + b.alerts, 0);
    };

    donutCenterRenderer = () => {
        let totalAlert = this.getTotalAlert();
        return (
            <span style={{ color: "white" }}>
                <h3 style={{ color: "white" }}>{totalAlert}</h3> Alerts
            </span>
        );
    };
    renderTooltip = (context) => {
        let totalAlert = this.getTotalAlert();
        const { category, series, value } = context.point || context;
        return (
            <div>
                {category} ({value}): {((value / totalAlert) * 100).toFixed(2)}%
            </div>
        );
    };
}

class AreaChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: [
                "2/1/2021",
                "2/2/2021",
                "2/3/2021",
                "2/4/2021",
                "2/5/2021",
                "2/6/2021",
                "2/7/2021",
                "2/8/2021",
                "2/9/2021",
                "2/10/2021",
                "2/11/2021",
                "2/12/2021",
                "2/13/2021",
                "2/14/2021",
                "2/15/2021",
                "2/16/2021",
                "2/17/2021",
                "2/18/2021",
                "2/19/2021",
                "2/20/2021",
                "2/21/2021",
                "2/22/2021",
                "2/23/2021",
            ],
            fakeData: [
                0.19,
                0.3,
                0.64,
                0.24,
                0.23,
                0.26,
                0.35,
                0.37,
                0.26,
                0.35,
                0.28,
                0.35,
                0.65,
                0.25,
                0.35,
                0.37,
                0.26,
                0.25,
                0.35,
                0.26,
                0.37,
                0.26,
                0.35,
            ],
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        let rem = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );
        return (
            <div>
                <Chart>
                    <ChartArea
                        margin={(20 / 16) * rem}
                        height={(480 / 16) * rem}
                        width={(1240 / 16) * rem}
                        background={"rgba(0,0,0,0)"}
                    />
                    <ChartTitle
                        color={"white"}
                        text="% Completion for February 2021"
                    />
                    <ChartValueAxis>
                        <ChartValueAxisItem>
                            <ChartValueAxisLabels color={"white"} />
                        </ChartValueAxisItem>
                    </ChartValueAxis>
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem categories={this.state.category}>
                            <ChartCategoryAxisLabels
                                rotation={-90}
                                color={"white"}
                            />
                        </ChartCategoryAxisItem>
                    </ChartCategoryAxis>
                    <ChartSeries>
                        <ChartSeriesItem
                            name="Open"
                            type="area"
                            stack={{ type: "100%" }}
                            data={this.state.fakeData}
                            color={blueColor}
                        />
                        <ChartSeriesItem
                            name="Closed"
                            type="area"
                            data={this.state.fakeData.map((a) => 1 - a)}
                            color={orangeColor}
                        />
                    </ChartSeries>
                    <ChartLegend
                        spacing={(40 / 16) * rem}
                        margin={(10 / 16) * rem}
                        visible={true}
                        position={"bottom"}
                        labels={{ color: "white" }}
                    />
                </Chart>
            </div>
        );
    }
}

class ModelLineChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        return (
            <div>
                <ModelLineChartItem
                    data={this.props.data}
                    stepSize={this.props.stepSize}
                    min={this.props.min}
                    max={this.props.max}
                />
            </div>
        );
    }
}
export { ModelLineChart, DonutChart, AreaChart };
